// azureAuth.ts
import { PublicClientApplication, Configuration, InteractionType } from "@azure/msal-browser";

// Function to create and return a new MSAL instance
export const createMSALInstance = (clientId: string, tenantId: string, redirectUri: string) => {
  const msalConfig: Configuration = {
    auth: {
      clientId: clientId,
      authority: `https://login.microsoftonline.com/${tenantId}`,
      redirectUri: redirectUri, // Dynamically set the redirect URI
    },
  };

  return new PublicClientApplication(msalConfig);
};

// Define login request (can be customized further if needed)
export const LoginRequest = {
  scopes: ["User.Read"], // Define the scopes required by your app
};
