const endpoints = {
  auth: {
    login: 'api/users/login',
    validate: 'api/users/validate_webapp_token',
    sendVerificationMail: 'api/users/send_verification_mail',
    verifyAccount: 'api/users/verify_account',
    IsAccountVerified: 'api/users/is_account_verified',
    deleteClient: 'api/users/delete_client',
    fcmToken: 'waiter/fcm_token',
  },
  discounts: {
    redeem_code_coupon: 'discount/redeem_code_coupon',
    check_code_coupon_validity: 'discount/check_code_coupon_validity'
  },
  restaurants: {
    restaurant: 'api/restaurants/',
    group: 'restaurant/restaurant_group',
    get_nearest_delivery: 'api/restaurants/get_nearest_delivery',
    order_program_slots: 'restaurant/order_program_slots',
    delivery_takeaway_landing: 'restaurant/delivery_takeaway/',
    legal_info: 'restaurant/legal_info',
    credits: 'credit/list_credits',
    catalog_sections: 'restaurant/catalog_sections/',
    get_theme: 'restaurant/get_restaurant_theme/',
  },
  users: {
    register:'api/users/',
    user:'api/users/retrieve',
    update: 'api/users/partial_update',
    give_loyalty_points: 'api/users/give_loyalty_points',
  },
  refresh: {
    token: 'api/token/refresh',
  },
  password: {
    token: 'authentication/forgot_password/',
    confirm: 'authentication/recover_password/',
    validate: 'api/password_reset/validate_token/',
  },
  reviews: {
    review: 'api/reviews/',
    get_feedback_data: 'api/reviews/get_feedback_data',
  },
  orders: {
    order: 'api/orders/',
  },
  payments: {
    payment: 'api/payments/retrieve/',
    payment_raw: 'api/payments/',
    get_client_payments: 'payment/get_client_payments',
  },
  favorites: {
    favorite: 'api/favorites/retrieve',
    add: 'api/favorites',
    delete: 'api/favorites/destroy',
  },
  tables: {
    table: 'api/tables/',
    qr: 'api/tables',
  },
  loyalty: {
    client: 'loyalty/client/restaurants',
    points: 'loyalty/client/points',
    restaurant: 'loyalty/client/restaurant_profile_data',
    zerosix: 'loyalty/client/zerosix_client_vouchers',
  }, 
  events: {
    event: 'restaurant/events',
    newEvent: 'restaurant/event',
    getCatalog: 'event/get_catalog',
    getPaymentSuccessfulData: 'event/get_payment_successful_data/'
  },
  product: {
    catalog: 'product/catalog?only_visibles=True',
    order_status: 'product/order_status',
    product_modifiers: 'product/product-modifiers?only_visibles=True',
    modifier_modifiers: 'product/modifier-modifiers?only_visibles=True',
    restaurant_suggestions: 'product/restaurant-suggestions',
    verify_order_items: "product/verify_order_items"
  },
  integrations: {
    mahou_login: 'integrations/mahou-login'
  },
  onboarding: {
    closing_time: 'api/onboarding/onboarding_closing_time',
  },
  trackPayLater: 'api/track_pay_later_attempt',
  booking: {
    initial_info: 'booking/get/initial_info',
    shifts_for_day: 'booking/list/booking_shift',
    book: 'booking/create',
    get_booking: 'booking/get',
    check_whitelist_email: 'booking/check_whitelist_email',
    send_booking_successful_email: 'booking/send_booking_successful_mail'
  },
  stock: {
    check_stock: '/stock/order_items_stock'
  }
};

export default endpoints;
