export enum IconType {
  User = 'USER',
  Plat = 'PLAT',
  Card = 'CARD',
  Store = 'STORE',
  Pay = 'PAY',
  About = 'ABOUT',
  Menu = 'MENU',
  MyProfile = 'MYPROFILE',
  Spanish = 'SPANISH',
  Catalan = 'CATALAN',
  English = 'ENGLISH',
  French = 'FRENCH',
  Italian = 'ITALIAN',
  German = 'GERMAN',
  Portuguese = 'PORTUGUESE',
  ArrowDown = "ARROWDOWN",
  Check = "CHECK",
  Position = "POSITION",
  Globe = "GLOBE",
  Phone = "PHONE",
  Evelope = "EVELOPE",
  Time = "TIME",
  Instagram = "INSTAGRAM",
  Facebook = "FACEBOOK",
  ArrowUp = "ARROWUP",
  Arrowback = "ARROWBACK",
  Apple = "APPLE",
  Google = "GOOGLE",
  Azure = "AZURE",
  Showpassword = "SHOWPASSWORD",
  Hidepassword  = "HIDEPASSWORD",
  Circlecheck= "CIRCLECHECK",
  List = "LIST",
  Favorite = "FAVORITE",
  Arrowforward = "ARROWFORWARD",
  Edit = "EDIT",
  Favoritefill = "FAVORITEFILL",
  Close = "CLOSE",
  Cart = "CART",
  NewCart = "NEWCART",
  Bin = "BIN",
  Eggs = "EGGS",
  Lupin = "LUPIN",
  Gluten = "GLUTEN",
  Sesame = "SESAME",
  Mustard = "MUSTARD",
  Fish = "FISH",
  Celery = "CELERY",
  Crustaceans = "CRUSTACEANS",
  Peanuts = "PEANUTS",
  Molluscs = "MOLLUSCS",
  Soy = "SOY",
  DriedFruit = "DRIED_FRUIT",
  Sulphites = "SULPHITES",
  Milk = "MILK",
  Calendar = "CALENDAR",
  Clock = "CLOCK",
  CashbackColor = "CASHBACK_COLOR",
  NoCashbackColor = "NO_CASHBACK_COLOR",
  CoinWithShadow = "COIN_WITH_SHADOW",
  PointColor = "POINT_COLOR",
  ZerosixColor = "ZEROSIX_COLOR",
  LoyaltyColor = "LOYALTY_COLOR",
  PayAll = "PAY_ALL",
  PaySplit = "PAY_SPLIT",
  MenuThemed = "MENU_THEMED",
  CardsThemed = "CARDS_THEMED",
  BasketThemed = "BASKET_THEMED",
  MailThemed = "MAIL_THEMED",
  LogoColor = "LOGO_COLOR",
  Language = "LANGUAGE",
  Receipt = "RECEIPT",
  ApplePay = "APPLE_PAY",
  ApplePayWhite = "APPLE_PAY_WHITE",
  GooglePay = "GOOGLE_PAY",
  C2P = "C2P",
  Cash = "CASH",
  In_Store= "IN_STORE",
  Visa = "VISA",
  Master = "MASTER",
  BizumColor = "BIZUM_COLOR",
  BankCard = 'BANK_CARD',
  Bizum = "BIZUM",
  Master_C2P = "MASTER_C2P",
  Cards = "CARDS",
  Food = "FOOD",
  Drink = "DRINK",
  Gift = "GIFT",
  Hand = "HAND",
  Point = "POINT",
  Cashback = "CASHBACK",
  Amazing = "AMAZING",
  Great = "GREAT",
  Good = "GOOD",
  Bad = "BAD",
  Tips = "TIPS",
  Allergies = "ALLERGIES",
  Moon = "MOON",
  Sun = "SUN",
  Gps = "GPS",
  Logo = "LOGO",
  Plus = "PLUS",
  Minus = "MINUS",
  BizumIsotype = 'BIZUMISOTYPE',
  Credit = "CREDIT", 
  Geolocation = "GEOLOCATION",
  ExclamationRound = "EXCLAMATION_ROUND",
  Yumminn = 'YUMMINN',
  ServiceClosed = 'SERVICE_CLOSED',
  Mail = 'MAIL',
  TabArrow = 'TAB_ARROW',
  KitchenHat = 'KITCHEN_HAT',
  QR = 'QR',
  Info = 'INFO',
  Star = 'STAR',
  Refresh = 'REFRESH',
  Hamburger = 'HAMBURGER',
  Separator = 'SEPARATOR',
  AlertBubble = 'ALERT_BUBBLE',
  ReceiptPaymentSuccessful = 'RECEIPT_PAYMENT_SUCCESSFUL',
  Ticket = 'TICKET',
  Verified = 'VERIFIED',
  Verified2 = 'VERIFIED_2',
  OrderBag = 'ORDER_BAG',
  Password = 'PASSWORD',
  Exit = 'EXIT',
  Error = 'ERROR',
  QrScan = 'QR_SCAN',
  Basket = 'BASKET',
  Table = 'TABLE',
  GPayLogo = 'GPAYLOGO',
  Warning = 'WARNING',
  Wifi = 'WIFI',
  Copy = 'COPY',
  CreditBanner = 'CREDIT_BANNER',
  LogoWhite = 'LOGO_WHITE',
  CashbackWithBg = 'CASHBACK_WITH_BG',
  Shield = 'SHIELD',
  CreditBannerDisabled = 'CREDIT_BANNER_DISABLED',
  Vegetarian = 'VEGETARIAN',
  Vegan = 'VEGAN',
  GlutenFree = 'GLUTEN_FREE',
  Kosher = 'KOSHER',
  Halal = 'HALAL',
  Spicy1 = 'SPICY_1',
  Spicy2 = 'SPICY_2',
  Spicy3 = 'SPICY_3',
  AdultsOnly = 'ADULTS_ONLY',
  NoPregnant = 'NO_PREGNANT',
  NoDriver = 'NO_DRIVER',
  WalletDone = 'WALLET_DONE',
  Tag = 'TAG',
  Settings = 'SETTINGS',
  CreditCashback = 'CREDIT_CASHBACK',
  CreditCashbackRed = 'CREDIT_CASHBACK_RED'
}