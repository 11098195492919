import { BaseCard, FlipCardContainer } from './CardContainers';

type Props = {
  front: React.ReactNode
  back: React.ReactNode
  flipped?: boolean
  flipBlocked?: boolean
  className?: string
  onFlip?: React.ChangeEventHandler
};

export const FlipCard = ({ front, back, flipped, flipBlocked, className = '', onFlip }: Props) => {
  console.log(`🐉 > FlipCard > flipBlocked:`, flipBlocked)
  return (
    <FlipCardContainer className={className}>
      <input
        type='checkbox'
        checked={onFlip ? flipped : undefined}
        defaultChecked={onFlip ? undefined : flipped}
        onChange={flipBlocked ? undefined : onFlip}
        disabled={flipBlocked}
      />
      <div>
        <BaseCard className='front'>
          {front}
        </BaseCard>
        <BaseCard className='back'>
          {back}
        </BaseCard>
      </div>
    </FlipCardContainer>
  );
};