import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Icon, IconType } from '../icons';
import { BackButton, CloseButton, PopupContainer, PopupContent } from './PopupContainers';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { AuthContext } from '../../context/auth/AuthContext';
import { ToastContainer } from 'react-toastify';
import { PopupContext } from '../../context/PopupContext';
import { useViewport } from '../../context/ViewportContext';

export type PopupProps = {
  isOpened: boolean
  header?: string | React.ReactNode
  noReturn?: boolean
  showCloseButton?: boolean
  onClose?: () => Promise<void> | void
  afterClose?: () => void
  afterGoBack?: () => void
  beforeGoBack?: () => void
  isPaymentFlowPopups?: boolean
  isReceiptListOrWalletPopup?: boolean
};

type Props = PopupProps & {
  children: React.ReactNode
  className?: string
};

const noReturnPopup = [
  PopupType.Cart,
  PopupType.Welcome,
  PopupType.CashbackRegister,
  PopupType.OutOfStock,
] as PopupType[];

export const Popup = ({
  isOpened,
  children,
  header,
  noReturn,
  showCloseButton = true,
  className = '',
  onClose,
  afterClose,
  afterGoBack,
  beforeGoBack,
  isPaymentFlowPopups,
  isReceiptListOrWalletPopup,
}: Props) => {
  const { popup } = useContext(PopupContext);
  const { isLogged } = useContext(AuthContext);
  const { orientation } = useViewport();
  
  const { goBackPopup, closePopup } = usePopupNavigation();
  
  const [shouldRenderChildren, setShouldRenderChildren] = useState(false);

  const close = () => {
    popupRef.current?.open && popupRef.current?.children?.[0]?.classList.add('close');
  };

  const open = () => {
    setShouldRenderChildren(true);
    !popupRef.current?.open && popupRef?.current?.showModal && popupRef?.current?.showModal?.();
    popupRef.current?.children?.[0]?.classList.add('open');
  };

  const goBack = async () => {
    beforeGoBack?.();  
    await goBackPopup();    
    afterGoBack?.();
  };

  const onAnimationEnd = async () => {
    if (popupRef.current?.children?.[0]?.classList.contains('close')) {
      popupRef.current?.close && popupRef.current?.close();
      popupRef.current?.children?.[0]?.classList.remove('close');

      setShouldRenderChildren(false);
      await (onClose || closePopup)();
      afterClose?.();
    } else {
      popupRef.current?.children?.[0]?.classList.remove('open');
    }
  };

  const popupRef = useRef<HTMLDialogElement | null>(null);

  const historyLength = popup.history.length;
  const lastPopup = popup.history[historyLength - 1]?.popup;

  const hideReturn = noReturn
    || noReturnPopup.includes(popup.current)
    || isLogged && lastPopup === PopupType.Register && historyLength === 1;

  useLayoutEffect(() => {
    isOpened ? open() : close();

    const handleTouch = (event: MouseEvent) => {
      const childNodes = popupRef.current?.childNodes as NodeList;
      const isPopup = Array.from(childNodes).some(node => node.contains(event.target as Node));
      
      !isPopup && !isPaymentFlowPopups && close();
    };

    isOpened && window.addEventListener('mousedown', handleTouch);

    return () => window.removeEventListener('mousedown', handleTouch);
  }, [isOpened]);

  const showReturnButton = !(hideReturn || historyLength <= 0) || popup.current === PopupType.PayNowOrLater || popup.current === PopupType.Wallet || popup.current === PopupType.Profile || (popup.current === PopupType.TipsSmall && orientation === 'landscape');
  const returnButtonOff = !!(popup.current === PopupType.Cart && lastPopup === "NOT_PRODUCTS_IN_TABLE");

  return (
    <PopupContainer
      className="dialog_popup"
      isReceiptListOrWalletPopup={isReceiptListOrWalletPopup || popup.current === PopupType.Register}
      ref={popupRef}
    >
      <PopupContent className={className} onAnimationEnd={onAnimationEnd}>
        {shouldRenderChildren &&
          <>
            {(header || showReturnButton || showCloseButton) &&
              <header>
                <BackButton 
                  onClick={goBack}
                  disabled={!showReturnButton}
                >
                  <Icon type={IconType.Arrowback}/>
                </BackButton>
                
                {header && (
                  typeof header === 'string' ?
                    <h4>{t(header)}</h4>
                  :
                    header
                )}
                <CloseButton
                  onClick={close}
                  disabled={!showCloseButton || (!noReturn && historyLength > 0 && !returnButtonOff && popup.current !== PopupType.OutOfStock)}
                >
                  <Icon type={IconType.Close}/>
                </CloseButton>
              </header>
            }
            {children}
          </>
        }
      </PopupContent>
      {isOpened &&
        <ToastContainer
          position={orientation === 'portrait' ? 'bottom-center' : 'bottom-right'}
          theme='colored'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          icon={false}
        />
      }
    </PopupContainer>
  );
};