import { useCallback, useContext } from "react";
import { isEqual } from 'lodash'
import { IndividualFreeProduct, OPCartFreeProduct, OPCartProduct, OPDisplayProduct } from "../../../context/app";
import { AppContext } from "../../../context/AppContext";
import { createNewCartProduct, getTotalCartPrice, getTotalProductDiscount, getTotalProductPrice } from "./productFunctions";
import { usePopupNavigation } from "../../../shared/utils/usePopupNavigation";
import { PopupType } from "../../../context/popups.enum";
import { RequestProduct } from "../../../api/product/Product.api";

const compareFreeProducts = (freeProduct: IndividualFreeProduct, cartFreeProduct: OPCartFreeProduct) => (
  freeProduct.id === cartFreeProduct.free_product_id &&
  freeProduct.assign_reason === cartFreeProduct.assign_reason
);

export const useCart = () => {
  const {
    state: {
      restaurant: {
        listFreeProducts,
      },
      checkout: {
        orderAndPayItems,
        freeProducts,
        productList,
      }
    },
    dispatch,
  } = useContext(AppContext);

  const { goToPopup } = usePopupNavigation();

  const handleVerifyOrderItems = async (orderItems: OPCartProduct[]) => {
    const result = await RequestProduct.verifyOrderItems(orderItems);
    return result;
  };

  const dispatchCart = useCallback(async(cart: OPCartProduct[]) => {
    const totalPrice = getTotalCartPrice(cart);
    const totalDiscount = getTotalProductDiscount(cart)
    const response: any = await handleVerifyOrderItems(cart)
    const signature = response?.data?.signature
    if(signature){
      localStorage.setItem("orderItemsSignature", signature)
    }
    if(totalPrice === 0){
      dispatch({
        type: 'UPDATE_TIP',
        payload: {
          selected: '',
          amount: 0
        }
      });
    }

    dispatch({
      type: 'UPDATE_ORDER_AND_PAY_ITEMS',
      payload: cart,
    });

    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        tempOrderAndPayItems: cart,
        myTotal: totalPrice,
        remaining: totalPrice,
        productDiscountCents: totalDiscount
      }
    });
    
    dispatchListFreeProducts(cart);
  }, [dispatch]);

  const dispatchProduct = async(product: OPDisplayProduct | OPDisplayProduct['id']) => {
    const selectedProduct = typeof product === 'number'
      ? productList.find(prod => prod.id === product)
      : product;

    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        selectedProduct,
      }
    });
  };

  const dispatchListFreeProducts = useCallback((cart: OPCartProduct[]) => {
    if (!listFreeProducts?.length) return;

    const individualFreeProducts = freeProducts.filter(prod => prod.assign_reason !== 'purchase');

    if (!cart.length) {
      dispatch({
        type: 'UPDATE_CHECKOUT',
        payload: {
          freeProducts: individualFreeProducts,
        }
      });

      return;
    }

    const newfreeProducts = listFreeProducts.reduce((freeProds, list) => {
      const promoProds = cart.filter(cartProd => list.product_ids.includes(cartProd.id))

      if (!promoProds.length) return freeProds;

      const cheapestProd = promoProds.sort((a, b) => getTotalProductPrice(a) - getTotalProductPrice(b))[0];

      freeProds.push({
        ...cheapestProd,
        amount: list.quantity,
        free_product_id: list.id,
        assign_reason: 'purchase',
        unique_id: performance.now(),
        external_id: list.external_id
      });

      return freeProds;
    }, [] as OPCartFreeProduct[]);

    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        freeProducts: [...individualFreeProducts, ...newfreeProducts],
      }
    });
  }, [dispatch, freeProducts]);

  const addProduct = useCallback((product: OPCartProduct, unitsToAdd?: number) => {
    const cart = [...orderAndPayItems];

    const sameProducts = cart.filter(item => item.id === product.id);

    dispatch({
      type: 'UPDATE_TIP',
      payload: {
        selected: '',
        amount: 0
      }
    });

    if (!sameProducts.length) {
      cart.push(product);
      dispatchCart(cart);
      
      return;
    }

    const cartIndex = cart.findIndex(item => isEqual(
      {...product, unique_id: null, amount: null },
      {...item, unique_id: null, amount: null }
    ));
    
    cartIndex !== -1 ? cart[cartIndex].amount++ : cart.push(product);

    if(cartIndex !== -1 && unitsToAdd) cart[cartIndex].amount = unitsToAdd

    dispatchCart(cart);
  }, [dispatchCart, orderAndPayItems]);

  const removeProduct = useCallback((uniqueId: number) => {
    const cart = [...orderAndPayItems];

    const cartIndex = cart.findIndex(item => item.unique_id === uniqueId);
    
    if (cartIndex === -1) return;

    cart[cartIndex].amount > 1 ? cart[cartIndex].amount-- : cart.splice(cartIndex, 1);

    dispatch({
      type: 'UPDATE_TIP',
      payload: {
        selected: '',
        amount: 0
      }
    });

    dispatchCart(cart);
  }, [dispatchCart, orderAndPayItems]);

  const clearCart = useCallback(() => dispatchCart([]), [dispatchCart]);

  const addFreeProduct = useCallback((freeProduct: IndividualFreeProduct) => {
    const newfreeProducts = [...freeProducts];

    if (newfreeProducts.some(cartFreeProduct => compareFreeProducts(freeProduct, cartFreeProduct))) return;

    const product = productList.find(prod => prod.id === freeProduct.product__id);

    if (!product) return;

    newfreeProducts.push({
      ...createNewCartProduct(product),
      amount: freeProduct.quantity,
      free_product_id: freeProduct.id,
      assign_reason: freeProduct.assign_reason,
      external_id: freeProduct.external_id
    });

    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        freeProducts: newfreeProducts,
      }
    });
  }, [dispatch, freeProducts, productList]);

  const removeFreeProduct = useCallback((freeProduct: IndividualFreeProduct) => {
    const newfreeProducts = freeProducts.filter(prod => !compareFreeProducts(freeProduct, prod));

    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        freeProducts: newfreeProducts,
      }
    });
  }, [dispatch]);

  const openProductPopup = useCallback((product: OPDisplayProduct | OPDisplayProduct['id'], totalAmount?: number|null) => {
    dispatchProduct(product);
    goToPopup(PopupType.Product, {unitsAlreadyInCart: totalAmount});
  }, [goToPopup]);

  const openEditSwimlineProductPopup = (product: OPDisplayProduct | OPDisplayProduct['id']) => {
    dispatchProduct(product);
    goToPopup(PopupType.SwimLineEditProduct);
  };

  return {
    dispatchProduct,
    addProduct,
    removeProduct,
    clearCart,
    dispatchCart,
    addFreeProduct,
    removeFreeProduct,
    openProductPopup,
    openEditSwimlineProductPopup,
  };
};