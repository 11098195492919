import { useCallback, useEffect, useRef, useState } from 'react';
import { Allergens as AllergensType, Tags as TagsType } from '../../context/app';
import { AllergenBadge } from '../allergens';
import { TagBadge } from '../tags';
import styled from 'styled-components';

type Props = {
  allergens?: AllergensType[]
  tags?: TagsType[]
  showLabel?: boolean
  className?: string
  smallAllergens?: boolean
};

export const AllergensAndTags = ({allergens = [], tags = [], showLabel = false, className = '', smallAllergens}: Props) => {
  const [hiddenElements, setHiddenElements] = useState(0);
  const observerRef = useRef<ResizeObserver>();
  
  const checkElements = useCallback((node: HTMLUListElement) => {
    if (node !== null) {
      observerRef.current = new ResizeObserver((entries) => {
        if (!entries.length) return;

        const list = entries[0].target as HTMLElement;
        const listItems = Array.from(list.childNodes) as HTMLElement[];
        const hiddenElements = listItems.filter(item => (
          (item.offsetLeft - list.offsetLeft > list.offsetWidth) ||
          (item.offsetTop - list.offsetTop > list.offsetHeight)
        ));

        setHiddenElements(hiddenElements.length);
      });

      observerRef.current.observe(node);
    }
  }, []);

  useEffect(() => {
    return () => observerRef.current?.disconnect();
  }, []);
  
  if (!allergens.length && !tags.length) return <></>;

  return (
    <ListContainer className={className}>
      <ul ref={checkElements}>
        {allergens?.map((allergen: any) =>
          <AllergenBadge
            key={'allergen-' + allergen}
            value={allergen}
            showLabel={showLabel}
            size={showLabel ? (smallAllergens ? 16 : 20) : (smallAllergens ? 14 : 16)}
          />)
        }
        {tags?.map((tag: any) =>
          <TagBadge
            key={'tag-' + tag}
            value={tag}
            showLabel={showLabel}
            size={showLabel ? 20 : 16}
          />
        )}
      </ul>
      {!showLabel && hiddenElements > 0 &&
        <span>+{hiddenElements}</span>
      }
    </ListContainer>
  );
};

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 100%;
  
  & > ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    flex: 1;
    max-height: 24px;
    margin: 0;
    padding: 0;
    overflow: hidden;

    &:has(span) {
      gap: 16px;
      max-height: unset;
      overflow: visible;
    }
  }

  & > span {
    font-size: ${({theme}) => theme.font_sm};
    font-weight: 500;
    line-height: 1;
    flex-shrink: 0;
  }
`;