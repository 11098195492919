import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext"; // Assuming your app context stores Azure details
import { createMSALInstance, LoginRequest } from "../azure/AzureAuth";
import Manager from "../../api/manager/Manager.api";
import endpoints from "../../api/endpoints/Endpoints.api";
import {Request as RegisterRequest}  from "../../api/register/Register.api";
import { postError } from "../../shared/utils/postError";

interface Props {
  Render: (props: any) => JSX.Element;
  from: string;
  lopd: boolean;
  news: boolean;
  onSuccess: (data: any, type: string, from: string, isFirstLogin: boolean) => void;
  onFailure: (error: any) => void;
}

const AzureADLogin = ({
  Render,
  from,
  lopd,
  news,
  onSuccess,
  onFailure,
}: Props) => {
  const {
    state: {
      restaurant: { azureClientId, azureTenantId }, // Values passed from context or props
    },
  } = useContext(AppContext);

  const [isInitialized, setIsInitialized] = useState(false);
  const [msalInstance, setMsalInstance] = useState<any>(null);

  const restaurantId = sessionStorage.getItem('restaurantId');
  const paymentId = sessionStorage.getItem("paymentId") || "";
  const restaurantGroupUuid = sessionStorage.getItem('restaurantGroupUuid') || '';

  // Initialize MSAL once when the component mounts
  useEffect(() => {
    const initializeMSAL = async () => {
      try {
        // Dynamically create the MSAL instance with the provided configuration
        const instance = createMSALInstance(azureClientId!, azureTenantId!, "http://localhost:3000/auth/azure");

        // Handle redirect response (in case the user was redirected after login)
        await instance.initialize();  // Ensures the instance is initialized properly

        // Store the MSAL instance in state
        setMsalInstance(instance);
        setIsInitialized(true);
      } catch (error) {
        console.error("Error initializing MSAL:", error);
      }
    };

    initializeMSAL();
  }, [azureClientId, azureTenantId]);  // Re-run initialization if any of the values change

  // Handle login via MSAL popup
  const handleLogin = async () => {
    if (!isInitialized) {
      console.error("MSAL is not initialized yet.");
      return;
    }

    try {
      // Trigger the login popup using MSAL
      const response = await msalInstance.loginPopup(LoginRequest);
      console.log(response)
      const restaurantId = Number(sessionStorage.getItem('restaurantId'));
      if (response && response.accessToken) {
        RegisterRequest.register({
              name: null,
              token: response.accessToken,
              type: "azure",
              lopd,
              news,
              paymentId,
              restaurantId,
              restaurantGroupUuid,
              paymentSuccessfulPopup: from === 'pop-up_save-card',
            })
              .then((data: any) => onSuccess(data, "azure", from, lopd))
              .catch((error: any) => {onFailure(error); postError(error)});
      }
    } catch (error) {
      onFailure(error); 
      postError(error)
    }
  };

  // If MSAL is not initialized, show a loading state
  if (!isInitialized) {
    return <div>Loading MSAL...</div>;
  }

  return <Render type="azure" renderProps={{ onClick: handleLogin }} />;
};

export default AzureADLogin;
