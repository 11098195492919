import { useTranslation } from "react-i18next";
import { IndividualFreeProduct, ListFreeProduct, OPCartFreeProduct } from "../../context/app";
import { getImageUrl } from "../../shared/functions";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import styled, { keyframes } from "styled-components";
import { FlipCard } from "./FlipCard";

const REASONS: Record<OPCartFreeProduct['assign_reason'], string> = {
  signup: 'for_being_registered',
  login: 'with_your_order_you_have',
  purchase: 'with_your_order_you_have',
  code_coupon: 'with_your_coupon',
};

const CART_SUBTITLE: Record<OPCartFreeProduct['assign_reason'], string> = {
  signup: 'for_your_registration',
  login: 'exclusive_for_clients',
  purchase: 'with_your_order',
  code_coupon: '',
};

const splitName = (product?: OPCartFreeProduct) => {
  if (!product) return { title: '', subtitle: '' };

  let title = `+${product.amount}`;
  let subtitle = '';
  let flag = false;

  const words = product.name.split(' ');

  words.forEach(word => {
    if (!flag && (title.length + word.length) < 20) {
      title += ' ' + word;
    } else {
      subtitle += ' ' + word;
      flag = true;
    }
  });

  return { title, subtitle };
};

type Props = {
  promo: IndividualFreeProduct | ListFreeProduct
  isCart?: boolean
  className?: string
};

type CardProps = Props & {
  product?: OPCartFreeProduct
};

const Front = ({ promo, product }: CardProps) => {
  const { t } = useTranslation();

  const title = promo?.call_to_action || (!product
    ? 'discover_surprise'
    : product.assign_reason === 'purchase'
      ? 'you_have_a_surprise'
      : 'discover_gift');

  return (
    <>
      <h5>{t(title)}</h5>
      <span>{t('tap_here')}</span>
    </>
  )
};

const Back = ({ promo, product, isCart }: CardProps) => {
  const { t } = useTranslation();

  const imgUrl = (product?.image ? getImageUrl(product.image) : getImageUrl(promo.banner)) as string;
  const assignReason = REASONS[product?.assign_reason || 'purchase'];
  const tagline = promo.tagline || t(assignReason);
  const { title: productTitle, subtitle: productSubtitle } = splitName(product);
  const title = isCart ? productTitle : promo.title || productTitle;
  const subtitle = isCart ? productSubtitle : promo.subtitle || productSubtitle;
  const cartSubtitle = promo.cart_subtitle || t(CART_SUBTITLE[product?.assign_reason || 'purchase']);

  return (
    <>
      <div>
        {!isCart &&
          <p>{tagline}</p>
        }
        <h5>{title}</h5>
        {subtitle &&
          <h6>{subtitle}</h6>
        }
        {isCart &&
          <p>{cartSubtitle}</p>
        }
        <span>{t((!product || product.assign_reason === 'purchase') ? 'free!' : 'gift!')}</span>
      </div>
      {imgUrl &&
        <img src={imgUrl} alt=''/>
      }
    </>
  )
};

export const FreeProductCard = ({ promo, isCart, className='' }: Props) => {
  const { state: { checkout: { freeProducts } } } = useContext(AppContext);

  const isListPromo = 'product_ids' in promo;
  const assignReason = (promo as IndividualFreeProduct)?.assign_reason || 'purchase';

  const product = (!isListPromo || isCart)
    ? freeProducts.find(prod => prod.free_product_id === promo.id && prod.assign_reason === assignReason)
    : undefined;

  return (
    <FreeProductFlipCard
      front={<Front promo={promo} product={product}/>}
      back={<Back promo={promo} product={product} isCart={isCart}/>}
      flipped={isCart}
      flipBlocked={isCart}
      textColor={promo.text_color}
      bgColor={promo.bg_color}
      className={className}
    />
  );
};

const shine = keyframes`
  25% {
    left: 150%;
  }

  100% {
    left: 150%;
  }
`;

const FreeProductFlipCard = styled(FlipCard)<{ textColor?: string, bgColor?: string }>`
  --textColor: ${({theme, textColor }) => textColor || theme.text};
  --bgColor: ${({ theme, bgColor }) => bgColor || theme.card_bg};

  min-height: ${({flipped}) => flipped ? '88px' : '154px'};
  height: ${({flipped}) => flipped ? '88px' : '154px'};
  max-height: ${({flipped}) => flipped ? '112px' : '154px'};
  padding: ${({flipped}) => flipped ? '0' : '8px'};
  box-sizing: border-box;
  display: block;
  
  .front, .back {
    width: 100%;
    height: 100%;
    color: var(--textColor);
    background-color: var(--bgColor);
    border-color: var(--bgColor);
    box-shadow: ${({theme}) => theme.shadow_xs};
  }

  .front {
    position: ${({flipped}) => flipped ? 'absolute' : 'relative'};
    outline: 2px dashed currentColor;
    outline-offset: -8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    h5 {
      font-size: ${({theme}) => theme.font_lg};
      font-weight: 600;
      line-height: 1;
      margin: 0;
    }
    
    span {
      font-size: ${({theme}) => theme.font_md};
      font-weight: 500;
      text-transform: uppercase;
      color: var(--bgColor);
      background-color: var(--textColor);
      border-radius: ${({theme}) => theme.radius_round};
      padding: 2px 8px;
    }
  }

  .back {
    position: ${({flipped}) => flipped ? 'relative' : 'absolute'};
    display: flex;
    padding: 8px;
    overflow: hidden;
    
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      text-align: start;
      flex: 1;
      padding: ${({flipped}) => flipped ? '0 8px 0 0' : '8px'};
      box-sizing: border-box;
    }
    
    p {
      font-size: ${({theme}) => theme.font_sm};
      font-weight: 500;
      line-height: 1.3;
      text-wrap: balance;
      margin: 0;
    }
    
    h5, h6 {
      font-weight: 600;
      line-height: 1.3;
      margin: 0;
    }
    
    h5 {
      font-size: ${({theme, flipped}) => flipped ?  theme.font_md : theme.font_lg};
    }

    h6 {
      font-size: ${({theme, flipped}) => flipped ?  theme.font_sm : theme.font_md};
    }
    
    span {
      font-size: ${({theme}) => theme.font_xs};
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      border: 1px solid currentColor;
      border-radius: ${({theme}) => theme.radius_round};
      padding: 4px 8px;
      margin-top: auto;
    }

    img {
      --size: ${({flipped}) => flipped ? '72px' : '122px'};
      
      height: var(--size);
      width: var(--size);
      object-fit: cover;
      border-radius: ${({theme}) => theme.radius_sm};
    }

    &::after {
      content: '';
      position: absolute;
      background-color: #FFF9;
      height: 100%;
      width: 10px;
      top: 0;
      left: -300%;
      opacity: 1;
      mix-blend-mode: hard-light;
      transform: skewX(-45deg);
      box-shadow:
        0px 0 10px 0 #FFF4,
        -40px 0 0 10px #FFF9,
        -40px 0 10px 10px #FFF4,
        300px 0 100px 400px #FFF2;
    }
  }

  &:has(:checked) .back::after {
    animation: ${shine} 6s ease-in-out infinite;
  }
`;