import { useContext } from "react";
import { PopupType } from "../../context/popups.enum";
import { PopupParams } from "../../context/app";
import { AuthContext } from "../../context/auth/AuthContext";
import { PopupContext } from "../../context/PopupContext";
import { isEqual, omit } from "lodash";
import { AppContext } from "../../context/AppContext";

const ANIMATION_DURATION = 500;

const keepOpenPopups = [
  PopupType.Checkout,
  PopupType.Splits,
  PopupType.SplitByProduct,
  PopupType.SplitByAmount,
  PopupType.SplitEvenly,
  PopupType.TipsSmall,
  PopupType.TipsBig,
  PopupType.PaymentDetail,
  PopupType.PaymentMethods,
  PopupType.Suggestions,
  PopupType.PayLater,
  PopupType.PayNowOrLater,
  PopupType.Cart,
];

const noHistoryPopups = [
  PopupType.None,
  PopupType.CashbackRegister,
  PopupType.Welcome,
  PopupType.VerificationEmail,
];

export const usePopupNavigation = () => {
  const { popup, dispatch } = useContext(PopupContext);
  const {
    state: {
       checkout: {
        selectedProduct
      }
    },
  } = useContext(AppContext);
  const { isLogged } = useContext(AuthContext);

  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const goToPopup = async (current: PopupType, params: PopupParams = null) => {
    const isRegisterAndLogged = isLogged && current === PopupType.Register;
    const isAlreadyOpen = popup.current === current && isEqual(
      omit(popup.params, 'product', 'unitsAlreadyInCart'),
      omit(params, 'product', 'unitsAlreadyInCart')
    );

    const history = noHistoryPopups.includes(popup.current) ? [...popup.history] : [...popup.history, {popup: popup.current, params: popup.params}];

    if(isAlreadyOpen && popup.current === PopupType.Product && params){
      params["product"] = selectedProduct

      dispatch({
        type: 'UPDATE_POPUP',
        payload: {
          params,
          history
        },
      });
    }

    if (isAlreadyOpen || isRegisterAndLogged) return;

    const keepOpen = keepOpenPopups.includes(current);
    const ms = popup.current === PopupType.None || keepOpen ? 0 : ANIMATION_DURATION;

    await wait(ms);

    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        current,
        params,
        history,
      },
    });
  };

  const goBackPopup = async () => {

    if(popup.current === PopupType.Profile || popup.current === PopupType.MyAccount){
      closePopup(); 
      return;
    }

    const history = [...popup.history];
    let lastPopup = history.pop() || {popup: PopupType.None, params: null};

    if (isLogged && lastPopup?.popup === PopupType.Register) {
      lastPopup = history.pop() || {popup: PopupType.None, params: null};
    }

    const { popup: current, params } = lastPopup;
    const keepOpen = keepOpenPopups.includes(popup.current);
    const ms = popup.current === PopupType.None || keepOpen ? 0 : ANIMATION_DURATION;

    await wait(ms);
  
    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        current,
        params,
        history,
      },
    });
  };

  const closePopup = async () => {

    if (popup.current === PopupType.None) return;

    await wait(ANIMATION_DURATION);
  
    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        current: PopupType.None,
        params: null,
        history: [],
      },
    });
  };

  const memorizeHistory = () => {
    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        memory: [...popup.history, {popup: popup.current, params: popup.params}],
      },
    });
  }

  const goToMemory = async () => {
    const history = [...popup.memory];
    let lastPopup = history.pop() || {popup: PopupType.None, params: null};

    if (isLogged && lastPopup?.popup === PopupType.Register) {
      lastPopup = history.pop() || {popup: PopupType.None, params: null};
    }

    const { popup: current, params } = lastPopup;
    const keepOpen = keepOpenPopups.includes(current)
    const ms = popup.current === PopupType.None || keepOpen ? 0 : ANIMATION_DURATION;
    
    await wait(ms);

    dispatch({
      type: 'UPDATE_POPUP',
      payload: {
        current,
        params,
        history,
        memory: [],
      },
    });
  };
  
  return { goToPopup, goBackPopup, closePopup, memorizeHistory, goToMemory };
};