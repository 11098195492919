import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import moment from "moment";
import { useLocation } from "react-router";

export const useRestaurantData = () => {
  const {
    state: {
      restaurantId: restaurantIdFromContext,
      tableNumber: tableNumberFromContext,
      restaurant: {
        id,
        uuid,
        restaurantGroupUuid: restaurantGroupUuidFromContext,
        name,
      },
    },
  } = useContext(AppContext);
  
  const location = useLocation();
  
  const state = location?.state as { restaurantFromNavigator?: string, tableFromNavigator?: string };
  const restaurantFromNavigator = state?.restaurantFromNavigator;
  const tableFromNavigator = state?.tableFromNavigator;
  
  const date = moment().format("YYYY-MM-DD");
  const lastScan = JSON.parse(localStorage.getItem('lastScan') || '{}');
  const restaurantFromLastScan = lastScan?.date === date && Number(lastScan.restaurantId);
  const tableFromLastScan = lastScan?.date === date && lastScan.tableNumber as string;
  
  const restaurantId = Number(sessionStorage.getItem("restaurantId"))
    || id
    || restaurantIdFromContext
    || Number(restaurantFromNavigator)
    || restaurantFromLastScan
    || 0;

  const restaurantUuid = sessionStorage.getItem("restaurantUuid")
    || uuid
    || '';

  const tableNumber = tableNumberFromContext
    || sessionStorage.getItem("tableNumber")
    || tableFromNavigator
    || tableFromLastScan
    || '';

  const restaurantGroupUuid = restaurantGroupUuidFromContext || sessionStorage.getItem('restaurantGroupUuid');

  const restaurantName = name || sessionStorage.getItem('restaurantName');

  const restaurantLogo = sessionStorage.getItem('restaurantLogo');

  return { restaurantId, restaurantUuid, tableNumber, restaurantGroupUuid, restaurantName, restaurantLogo };
};