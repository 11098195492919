import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import { Font } from '../../../elements/fonts';
import { FontSize } from '../../../elements/fonts/enums';
import { useTheme } from 'styled-components';
import { RegisterContainer } from '../LoyaltyContainers';
import { Loading } from '../../../elements';
import zerosix_logo from "../../../assets/images/zerosix_logo.svg";
import zerosix_register_ok from "../../../assets/images/zerosix_register_ok.svg";
import zerosix_register_fail from "../../../assets/images/zerosix_register_fail.svg";
import { useLocation } from 'react-router-dom';


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const RegisterPopup = ({setResponse, response, setInfoUrl, infoUrl} : any) => {
    const {t} = useTranslation();
    const query = useQuery();
    const restaurantName = sessionStorage.getItem('restaurantName');
    const restaurantId = sessionStorage.getItem('restaurantId');
    const clientPhone = sessionStorage.getItem('client_phone');
    const [phone, setPhone] = useState(clientPhone != 'null' ? clientPhone : '')
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(true)
    const theme: any = useTheme();
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const paymentId = query.get('paymentId');
    function addAuthorizationHeaders(headers: any) {
      const token: any = localStorage.getItem("token_yumminn");
      const type: any = localStorage.getItem("type");
      if (token && type && token !== "undefined" && type !== "undefined") {
        headers["Authorization"] = token;
        headers["Authorization-Type"] = type;
      }
      const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
      if (yumminnToken) {
          headers["yumminn-token"] = yumminnToken;
      }
      return headers;
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
        const data = {"restaurant": restaurantId,
                      "phone": phone,
                      "paymentId": paymentId ? paymentId : null}
        const response = await fetch(`${REACT_APP_BASE_URL}/loyalty/client/zerosix_create_contact`, {
        method: "POST",
        body: JSON.stringify(data),
        headers
        });
        if (response.status == 200) {
          const res = await response.json()
          if (phone){
            sessionStorage.setItem('client_phone', phone)
          }
          setInfoUrl(res["info_url"])
          setResponse("ok")
          setIsLoading(false)
        } else {
          setResponse("error")
          setIsLoading(false)
        }
      }
       
      const validatePhone = (phone:string) => {
         
        const regex_patter_only_numbers = /^\d{8}(?:\d{1})?$/
        ;
    
        const isValid = String(phone).match(regex_patter_only_numbers);
        if(isValid){
          setIsValid(true)
        } else {
          setIsValid(false)
        }
        return
      };
    
        
      const handleChangePhone = (e: any) => {
        validatePhone(e)
        setPhone(e);
      };
    
    return isLoading ? (<Loading parentComponent='RegisterPopup'/>) : (
        response == "error"  ? (
          <RegisterContainer>
            <img style={{width: "80px", height: "80px", alignSelf: 'center'}} src={zerosix_register_fail}/>
            <div style={{fontSize: "18px", fontWeight: 600, textAlign: 'center', padding: "0 15px"}}>{t('we could not create your zerosix card')}</div>
            <div style={{fontSize: "15px", fontWeight: 400, textAlign: 'center', marginTop: '10px'}}>{t('try again later')}</div>
          </RegisterContainer>) : 
          response == "ok" ? (
            <RegisterContainer>
            <img style={{width: "80px", height: "80px", alignSelf: 'center'}} src={zerosix_register_ok}/>
            <div style={{fontSize: "18px", fontWeight: 600, textAlign: 'center'}}>{t('you already have your zerosix card')}</div>
            <div style={{fontSize: "15px", fontWeight: 400, textAlign: 'center', marginTop: '10px'}}>{t('confirm the sms in your phone')}</div>
            <div style={{border: 'solid #E20E1D', alignSelf: 'center', padding: '0 20px', height: '35px', alignItems: 'center', borderRadius: '20px', display: 'flex', justifyContent: 'center', margin: "20px", color: '#E20E1D'}} onClick={() => window.open(infoUrl,"_self")}>            
                {t('more information')}
              </div>
            </RegisterContainer>) :
          (<RegisterContainer>
            <img style={{width: "80px", height: "80px", alignSelf: 'center'}} src={zerosix_logo}/>
            <div style={{paddingTop: '15px', width: "70%", fontSize: "18px", fontWeight: 600, textAlign: 'center', alignSelf: 'center'}}>
                  {t('do you want to create your zerosix card for', {restaurantName})}
            </div> 
            <div style={{paddingTop: '15px', width: "70%", fontSize: "16px", fontWeight: 1, textAlign: 'center', alignSelf: 'center'}}>
                  {t('earn points with each visit and obtain discounts')}
            </div>
            <div style={{margin: '10px 0 0 30px', fontSize: "16px", fontWeight: 600}}>
              {t('phone')}
            </div>
            <div style={{display: 'flex', flexDirection: 'column', margin: '5px 0 0 30px'}}>  
            <input
              style={{height: '30px', fontSize: '14px', width: '90%', borderRadius: "9px", border: `2px solid ${theme.gray_40}`, display: "block"}}
              placeholder={"Tu teléfono"}
              value={phone ? phone : undefined}
              onChange={e => handleChangePhone(e.target.value)} 
              name={'zerosix_phone'}                
            />
              {!isValid ? <Font size={FontSize.Small} text={t('valid_phone')} color="red"/> : null}
              </div>
              <div style={{alignSelf: 'center', width: '85%', height: '40px', alignItems: 'center', borderRadius: '20px', display: 'flex', justifyContent: 'center', margin: "20px", color: '#FFF', background: isValid && phone != "" ? "linear-gradient(to right, #E20E1D, #FD5700)" : "gray"}} onClick={() => isValid && phone != "" ? handleSubmit() : ""}>            
                {t('create')}
              </div>
          </RegisterContainer>))
    
};
